<template>
  <div class="yogi">
    <app-tour :steps="steps" />
    <div v-if="isLoadingFetch" style=" z-index: 99;" class="mb-2">
      <b-progress
        animated
        show-progress
        variant="primary"
        :max="100"
        height="1.3rem"
        :striped="true"
        :class="'progress-bar-info'"
      >
        <b-progress-bar
          :value="100"
          :label="`Memuat data Visitor Khusus . . .`"
          variant="primary"
        />
      </b-progress>
    </div>
    <b-badge
      variant="danger"
      class="badge-glow yo-guide button"
      @click="tourStart"
    >
      <feather-icon icon="HelpCircleIcon" /> Guide
    </b-badge>
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookIcon" class="mr-25" />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          Riwayat Induksi → "History Induksi" / "Log Induksi"
          Menampilkan semua <b-badge variant="success"> riwayat induksi </b-badge> , baik yang sudah maupun yang belum selesai.
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
          <b-spinner type="grow" small />
        </strong>
      </div>
    </b-alert>
    <b-card>
      <YoGoodTable
        class="yo-good-table"
        id="tour-table"
        :fields="tableColumns"
        :data="tableData"
        :total="totalItems"
        :currentPage="currentPage"
        :perPage="perPage"
        @page-changed="handlePageChange"
        @per-page-changed="handlePerPageChange"
        @edit="handleEdit"
        @delete="handleDelete"
        @update-is-active="updateIsActive"
        @on-search="handleSearch"
      />
      <div class="divider">
        <div class="divider-text"></div>
      </div>
      <feather-icon icon="DatabaseIcon" class="mr-25" />
      <span
        >Halaman hanya dapat dilihat oleh : [manage all, team-induction]</span
      >
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AppTour from '@core/components/app-tour/AppTour.vue'
import { BCard, BSpinner, BAlert, BBadge } from 'bootstrap-vue'
import YoGoodTable from '@/views/components/YoGoodTableinduction.vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppTour,
    BCard,
    BSpinner,
    BAlert,
    YoGoodTable,
    BBadge
  },
  directives: {
    Ripple
  },
  data() {
    return {
      isLoadingFetch: false,
      stepID: 0,
      /* good table */
      totalItems: 0, // Total number of items from API
      currentPage: 1, // Current page number
      perPage: 10, // Items per page
      apiBaseUrl: 'vw_visitor_hs_induction_all', // API base URL

      tableColumns: [
        { label: 'Badge', field: 'badge', width: '360px' },
        { label: 'Nama / Whatsapp / Induction Status', field: 'full_name', width: '200px' },
        // { label: 'Asal / Status', field: 'status', width: '120px' },
        // { label: 'Last Time ON', field: 'last_in', width: '250px' },
        // { label: 'ID', field: 'id', width: '60px' }
      ],
      tableData: [],
      badgeTitle: {
        1: '[ A.1 ] Tamu ( Visitor ) - Umum',
        2: '[ A.2 ] Awak 2 Truck',
        3: '[ B ] Identitas / Karyawan / Rutinitas',
        4: '[ C ] Pekerja Harian',
        5: '[ D ] Pengurus Agent BBM'
      },
      catTitle: {
        1: 'SAL',
        2: 'PIL',
        3: 'SUL',
        4: 'SPL',
        5: 'PATRA',
        6: 'TEM',
        7: 'SLB',
      },
      timeoutDebounce: null,
    }
  },
  setup() {
    const { watchNotification } = useAppConfig()
    return {
      watchNotification /* FirebaseFCM */
    }
  },
  watch: {
    watchNotification() {
      console.log('anIncomingNotification ::', this.watchNotification)
      try {
        let high_light_data = this.watchNotification.data[`screen_re_fetch`]
        let is_already_induction = this.watchNotification.data[`state_induction`]
        if(is_already_induction === "true" && this.iAmHandleInduction === false){
          let will_be_refresh = ['induction-completed']
          let go_refresh_land = will_be_refresh.some((data) =>
            high_light_data.includes(data)
          )
          console.log('vgo_refresh_land:: land', go_refresh_land)
          if (go_refresh_land) {
            this.fetchData()
          }
        }
      } catch (error) {
        console.log('an error re fetch land', error)
      }
    }
  },
  computed: {
    // Computed property for steps
    steps() {
      return [
        {
          target: '#tour-table #tour-qrcode-status',
          header: {
            title: 'Status QRCode'
          },
          content: 'Status QRCode awal pertama buat `Available` , ketika sedang digunakan `Used` dan ketika selesai digunakan `Expired`'
        },
      ]
    }
  },
  mounted() {
    this.fetchData() // Fetch initial data
  },
  created() {},
  methods: {
    moment,
    handleSearch(searchTerm) {
      if (searchTerm.length <= 0 || searchTerm.length > 0) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchData(1, this.perPage, searchTerm), 700)
      }
    },
    setLoading(state) {
      this.isLoadingFetch = state
    },
    tourStart() {
      // Start the tour using the name 'vuexyTour'
      if (this.$tours.vuexyTour) {
        this.$tours.vuexyTour.start()
      } else {
        console.error('Tour instance not found.')
      }
    },
    // Fetch data from API
    async fetchData(page = 1, size = 10, searchTerm = null) {
      this.setLoading(true)
      try {
        // Make API request using useJwt
        const response =  !searchTerm === true ? await useJwt.http.get(
          `${this.apiBaseUrl}?page=${page - 1}&size=${size}`
        ) : await useJwt.http.get(
          `${this.apiBaseUrl}?page=${page - 1}&size=${size}&searchTerm=${searchTerm}`
        )

        // Assuming response.data contains the actual data
        const data = response.data

        if (data.success) {
          this.setLoading(false)
          // Assign the fetched data to the table
          try {
            this.stepID = data.vw_visitor_hs_induction_alls[0].id
            console.log('this', this.stepID)
          } catch (error) {
            console.log('errors this', error)

          }

          // Assign the fetched data to the table
          this.tableData = data.vw_visitor_hs_induction_alls.map((visitor) => ({
            id: visitor.id,
            is_more_than_6_months: visitor.is_more_than_6_months,
            induction_date: visitor.induction_date,
            ket: visitor.ket_keperluan,
            badge: `[ ${visitor.badge_code} ] ${visitor.badge_name}`,
            cat: `${visitor.category}`,
            nomor_whatsapp: visitor.phone,
            address: visitor.address,
            full_name: visitor.full_name,
            ktp: visitor.no_ktp,
            perusahaan: visitor.from_company,
            last_out: visitor.last_out
              ? moment(visitor.last_out, 'YYYY-MM-DDTHH:mm:ssZ').format(
                  'DD MMMM YYYY  HH:mm'
                )
              : 'Not Available',
          }))
          this.totalItems = data.total_items
          this.currentPage = data.current_page + 1 // Adjust for 0-based index
          this.perPage = size
        } else {
          console.error('Failed to fetch data: Invalid success flag')
        }
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    },

    // Handle page change event from YoGoodTable
    handlePageChange(newPage) {
      this.fetchData(newPage, this.perPage)
    },

    // Handle per page change event from YoGoodTable
    handlePerPageChange(newPerPage) {
      this.fetchData(this.currentPage, newPerPage)
    },

    handleEdit(row) {
      // Edit logic here
    },
    handleDelete(row) {
      // Delete logic here
    },
    updateIsActive(item, state) {
      const value = !item.is_active
      try {
        console.log('item', item)
        const dataItem = this.tableData.find((d) => d.id === item.id)

        useJwt.http
          .put(`visitor_qrcode/${state}/${item.id}`, {
            value: value,
            qrcode_link: item.qrcode_link
          })
          .then((res) => {
            // Update the local data
            this.$set(dataItem, 'is_active', value)
            this.$swal({
              position: 'bottom-end',
              icon: 'success',
              title: `${res.data.message}`,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          })
          .catch((err) => {
            this.$set(dataItem, 'is_active', !value)
            console.error('Error updating status:', err)
          })
      } catch (error) {
        console.error('Error updating is_active:', error)
        this.$set(dataItem, 'is_active', !value)
      }
    }
  }
}
</script>
<style lang="scss">
.yo-guide {
  position: fixed;
  bottom: 4%;
  right: 20px;
  z-index: 1031;

  .btn {
    box-shadow: 0 0 20px 5px rgba(115, 103, 240, 0.8) !important;
    transition: box-shadow 0.3s ease-in-out;

    // Glow animation effect
    animation: glow 1.5s infinite alternate;

    &:hover {
      box-shadow: 0 0 10px 3px rgba(115, 103, 240, 1) !important;
      animation: none; // Stop glowing effect on hover
    }
  }
}

// Keyframes for glowing pulse effect
@keyframes glow {
  0% {
    box-shadow: 0 0 20px 5px rgba(115, 103, 240, 0.8);
  }
  100% {
    box-shadow: 0 0 30px 10px rgba(115, 103, 240, 1);
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>